import React from "react";
import HurricanePage from "components/HurricanePage.js";
import GlossyMessage from "components/GlossyMessage.js";

export default function PaymentMessage({ type }) {

    const isSuccess = (type.toLowerCase() === 'success');
    const msg = isSuccess ? 'Thanks for your payment, we will contact you shortly!' :
        'An error occurred, unable to process your payment :(';

    return (
        <HurricanePage>
            
            {
                (isSuccess) && <GlossyMessage message={msg} type='success'/>
            }
            {
                (!isSuccess) && <GlossyMessage message={msg} type='error' />
            } 
           
        </HurricanePage>
    )
}
