
import React , { createContext, useState} from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
// import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit
import Index from "views/Index.js";
// import NucleoIcons from "views/NucleoIcons.js";
import Dashboard from "views/Dashboard.js";
import LandingPage from "views/examples/LandingPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import Form from "views/Form";
import Search from "views/Search";
import Payment from "views/Payment";
import EftPayment from "views/eft-payment.js";
import PaymentMessage from "views/PaymentMessage";
import './firebase-config.js'; 

ReactDOM.render(<AppRouter/>, document.getElementById("root"));

function AppRouter(){

  const [rentDetails, setRentDetails] = useState({});

  return (
      <BrowserRouter>
      <Switch>
        <Switch>
          <Route path="/index" render={(props) => <Index {...props} setRentDetails={setRentDetails} />} />
          <Route path="/search" render={(props) => <Search {...props} rentDetails={rentDetails} setRentDetails={setRentDetails} />} />
          <Route path="/form" render={(props) => <Form {...props} rentDetails={rentDetails} setRentDetails={setRentDetails} />} />
          {/* <Route
            path="/nucleo-icons"
            render={(props) => <NucleoIcons {...props} />}
          /> */}
          <Route
            path="/landing-page"
            render={(props) => <LandingPage {...props} />}
          />
          <Route
            path="/profile-page"
            render={(props) => <ProfilePage {...props} />}
          />
          <Route
            path="/dashboard"
            render={(props) => <Dashboard {...props} />}
          />
          <Route
            path="/SecurePayment"
            render={(props) => <Payment {...props} rentDetails={rentDetails} />}
          />
          <Route
            path="/SuccessPaymentMessage"
            render={(props) => <PaymentMessage {...props} type="success" />}
          />
          <Route
            path="/EftPayment"
            render={(props) => <EftPayment {...props} rentDetails={rentDetails} type="success" />}
          />          
          <Route
            path="/ErrorPaymentMessage"
            render={(props) => <PaymentMessage {...props}  type="error" />}
          />
          
          <Redirect to="/index" />
          <Redirect from="/" to="/index" />
        </Switch>
      </Switch>
      </BrowserRouter>
  );
}