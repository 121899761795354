import React from "react";
import classes from "./location.module.css";
import classNames from 'classnames';
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';

export default function SearchLocationInput({ identifier, setLocation, placeholder,style }) {
  const [predictedAddresses, setPredictedAddresses] = React.useState([]);

  let busyLookingUp = false;
  let isFirstLookup = true;
  const onChange = async (ev) => {
    
    // // if(ev.target.value.length !== 5 && ev.target.value.length !== 10){
    // if(ev.target.value[ev.target.value.length - 1] !== ' '){
    //   return;
    // }

    const searchTerm = ev.target.value;
    if(busyLookingUp || searchTerm?.length < 3) {
      return;
    }

    busyLookingUp = true;

    const doLookup = async () => {

      const response = await fetch(
        `https://api.tomtom.com/search/2/search/${searchTerm}.json?lat=-29.302469&lon=24.895524`+
        '&radius=1000000&minFuzzyLevel=1&maxFuzzyLevel=2&view=Unified'+
        '&relatedPois=off&key=m4AKkptB5wD9nJ48oA6MG1h87pIuFxw5', 
        {
          method: 'GET'
        }
      );
  
      const data = await response.json();
  
      setPredictedAddresses(data.results);
      busyLookingUp = false;
    }

    if(isFirstLookup) doLookup();
    else setTimeout(doLookup, 1000);

    isFirstLookup = false;

  }

  const captureMyLocation = () => {

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const newLocation = `${position.coords.latitude},${position.coords.longitude}`;
        document.getElementById(identifier).value = 'My Current Location';
        // alert(newLocation);
        setLocation(newLocation);
      });
    } else { 
      toast.error('Geolocation is not supported by this browser');
    }

  };

  const onItemClick = (ev) => {
    document.getElementById(identifier).value = ev.target.outerText;
    setPredictedAddresses([]);
    setLocation(ev.target.outerText);
  }

  const clNames = classNames(classes.locationInptOptions, { [classes.showResults]: predictedAddresses.length > 0 });
  return (
    <div >
      <input id={identifier} style={style} placeholder={placeholder} onChange={onChange}
       className={classes.inpt} autoComplete="off"/>

      <Tooltip title='Capture My Current Location'>

            <MyLocationOutlinedIcon color="action" className={classes.myLocation}
              onClick={captureMyLocation}/>
      </Tooltip>

      <div className={clNames}>

      {
        predictedAddresses.map(item => 
          <div onClick={onItemClick}>{item.address.freeformAddress}</div>
        )
        
      }
      </div>
        


    </div>
  );
}



