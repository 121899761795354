import React from "react";
import DashboardContent from './Dashboard-Content.js'
import Login from './Login.js'



function Dashboard() {

  const [loggedIn, setLoggedIn] = React.useState(false);
 
  return (
    <div>
      {
        (loggedIn) && <DashboardContent/> 
      }
      {
        (!loggedIn) && <Login setLoggedIn={setLoggedIn} /> 
      }
    </div>
  );
}

export default Dashboard;
