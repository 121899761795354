import React from 'react';
import moment from 'moment';
import classes from './Rentals.module.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import SensorDoorOutlinedIcon from '@mui/icons-material/SensorDoorOutlined';
import AirlineSeatReclineExtraTwoToneIcon from '@mui/icons-material/AirlineSeatReclineExtraTwoTone';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';

import { dbSave, dbListenTo, dbUpdate} from '../firebase-config.js'; 


const label = { inputProps: { 'aria-label': 'Switch demo' } };

export default function Cars() {

    const [cars, setCars] = React.useState([]);
    React.useEffect(() => {
        
        dbListenTo('cars', (data) => {
            //alert(`new data ${JSON.stringify(data)}`);
            if(!data) return;
            data.forEach(element => {
                element.currentBooking = getCurrentBooking(element);
                element.nextBooking = getNextBooking(element);
            });

            setCars(data);
        });

    }, []);

    const toggleAvailable = (id) => {

        const available = (cars[id].available != null) ? !cars[id].available : true;
        const newValue = {
            ...cars[id],
            'available': available
        };

        setCars(prevValue => {
            const newCarList = [...prevValue];
            newCarList[id] = newValue;
            return newCarList
        });
        
        const toBeSavedValue = {...newValue};
        delete toBeSavedValue.currentBooking;
        delete toBeSavedValue.nextBooking;
        dbUpdate(`cars/${id}`, toBeSavedValue);
    };

    function getCurrentBooking(car){

        if(!car.bookings || Object.keys(car.bookings).length === 0) return null;

        let currentBooking;

        Object.keys(car.bookings).forEach(key => {
            const booking = car.bookings[key];
            
            if (
                (new Date().toLocaleDateString() === new Date(booking.from).toLocaleDateString()) ||
                (new Date(booking.from) <= new Date() && new Date() <= new Date(booking.to))
            ) {
                if(!currentBooking) currentBooking = booking;
                else {
                    if(new Date(booking.from) < new Date(currentBooking.from))
                        currentBooking = booking;
                }
            }
        });
        return currentBooking;
    }

    function getNextBooking(car){

        if(!car.bookings || Object.keys(car.bookings).length === 0) return null;
        
        let nextBookingDate;

        Object.keys(car.bookings).forEach(key => {
            const booking = car.bookings[key];
            
            if(booking.from !== car.currentBooking?.from && new Date(booking.from) > new Date()){
                if(!nextBookingDate) nextBookingDate = booking;
                else {
                    if(new Date(booking.from) > new Date(nextBookingDate.from))
                        nextBookingDate = booking;
                }
            }
        });

        return nextBookingDate;
    }

    return (
        <>
            <div className={classes.RContainer} style={{ marginTop: 30 }}>
                <h2> Cars</h2>
                <TableContainer component={Paper}  sx={{ borderRadius: 3, padding: 3 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell>Car Details</TableCell>
                                <TableCell >Current booking</TableCell>
                                <TableCell >Next booking</TableCell>
                                {/* <TableCell >Price Per Day</TableCell> */}
                                <TableCell align="center">Available</TableCell>
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {cars.map((row, index) => 
                        
                                (row) &&
                                (<TableRow
                                    className="fRow"
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell >
                                            <img 
                                                src={require(`assets/img/cars/${row.image}`)?.default}
                                                width={100}
                                                style={{borderRadius: 10}}
                                                alt={row.name}
                                            />
                                        </TableCell>
                                        <TableCell component="th" scope="row" >
                                            Name: {row.name} <br/>
                                            Color: {row.color} <br/>
                                            Reg: {row.registration} <br/>
                                            PPD: R{row.price} <br/>
                                            <div>
                                                
                                                <SensorDoorOutlinedIcon /> {row.specs.doors}  {' | '}
                                                <AirlineSeatReclineExtraTwoToneIcon /> {row.specs.seats} {' | '}
                                                <SettingsOutlinedIcon /> {row.specs.gear?.[0]?.toUpperCase()}  {' | '}
                                                <LocalGasStationOutlinedIcon /> {row.specs.fuel?.[0]?.toUpperCase()}
                                                {row.specs.bulletproof && <span> | <SecurityOutlinedIcon /> </span>}  

                                            </div>
                                        </TableCell>
                                        <TableCell >
                                            {
                                            (row.currentBooking) && (<div>
                                                From: <span style={{color:'red'}}>{row.currentBooking?.from}</span> <br/>
                                                To: <span style={{color:'green'}}>{row.currentBooking?.to}</span>
                                            </div>)
                                            }
                                        </TableCell>
                                        <TableCell >
                                            {
                                                (row.nextBooking) && (
                                                <div>
                                                    From: <span style={{color:'red'}}>{row.nextBooking.from}</span> <br/>
                                                    To: <span style={{color:'green'}}>{row.nextBooking.to}</span>
                                                </div>
                                                )
                                            }
                                        </TableCell>
                                        {/* <TableCell >R{row.price}</TableCell> */}
                                        <TableCell align="center"><Checkbox onChange={() => toggleAvailable(index)} checked={row.available} /></TableCell>
                                    
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

        </>

    )
}