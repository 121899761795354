import classNames from "classnames";
import React, { useState } from "react";
import classes from './OnOffButton.module.css';

export default function OnOffButton({ label, icon, onChange}) {

    const [on, setOn] = useState(false);


    const handleChange = () => {

        const newValue = !on;
        setOn(newValue);
        onChange?.(newValue);
    };


    const clNames = classNames(classes.onOffBtn, 
        { [classes.onBtn]: on });


    return (

            <div className={clNames} onClick={handleChange}>
                <div>  {icon} {label} </div>
            </div>
    )
}
