import React from 'react';
import classes from './circular-button.module.css';

export default function CircularButton({ icon , onClick }){

    return (
        <button className={classes.btn} onClick={onClick}>
            { icon }
        </button>
    );

}