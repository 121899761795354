import React from "react";
import HurricanePage from "components/HurricanePage.js";
import GlossyMessage from "components/GlossyMessage.js";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';

export default function EftPayment({ rentDetails }) {

    const transactionId = rentDetails.eftRef || "Manual Payment";
    
    return (
        <HurricanePage>
            
            
            <GlossyMessage 
            message = {
                <div>
                    <div>Thank you, please use one of the bank details below to make a payment 
                        <br/>and send proof of payment to 
                        <a href="mailto:info@hurricanecarrentals.co.za" style={{fontSize: '1rem'}}> info@hurricanecarrentals.co.za</a></div>
                    <br/>
                    <div style={{textAlign: 'center'}}>
                        <Card sx={{  textAlign:'left', minWidth: 200, maxWidth: 275, margin: 'auto', marginRight: 2, display:'inline-block'}}>
                            <CardContent>
                                {/* <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Word of the Day
                                </Typography> */}
                                <Typography variant="h5" component="div">
                                FNB 
                                </Typography>
                                {/* <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                adjective
                                </Typography> */}
                                <Typography variant="body2">
                                Account Number 62926724437 <br />
                                Account Type: BUSINESS ACCOUNT <br />
                                Branch Code: 200909 <br />
                                Swift Code: FIRNZAJJ <br />
                                Use Reference: {transactionId}
                                </Typography>
                            </CardContent>
                        </Card>

                        <Card sx={{ textAlign:'left', minWidth: 200, maxWidth: 275, margin: 'auto', marginRight: 2, display:'inline-block'}}>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                Nedbank 
                                </Typography>
                                <Typography variant="body2">
                                Account Number 1222749777 <br />
                                Account Type: CURRENT ACCOUNT <br />
                                Branch Code: 198765 <br />
                                Swift Code: NEDSZAJJ <br />
                                Use Reference: {transactionId}
                                </Typography>
                            </CardContent>
                        </Card>
                    </div>
                </div>
            } 
            />
           
           
        </HurricanePage>
    )
}
