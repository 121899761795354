import React from "react";
import moment from "moment";
import classes from './Rentals.module.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import { dbSave, dbListenTo, dbUpdate, dbDelete } from '../firebase-config.js';
import Checkbox from '@mui/material/Checkbox';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import BadgeIcon from '@mui/icons-material/Badge';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import PersonIcon from '@mui/icons-material/Person';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import DateRangeIcon from '@mui/icons-material/DateRange';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import KeyIcon from '@mui/icons-material/Key';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
const label = { inputProps: { 'aria-label': 'Switch demo' } };

export default function Rentals() {
    const [rentals, setRentals] = React.useState({});
    const [cars, setCars] = React.useState([]);

    React.useEffect(() => {

        dbListenTo('rentals', (data) => {
            if (data) setRentals(data);
            else setRentals({});
        });

        dbListenTo('cars', (data) => {
            if (data) setCars(data);
            else setCars([]);

        });

    }, []);

    const approveRental = (uniqueId) => {

        const approved = (rentals[uniqueId].approved != null) ? !rentals[uniqueId].approved : true;
        const newValue = {
            ...rentals[uniqueId],
            'approved': approved,
            'approvedDate': approved ? moment(new Date()).format('YYYY-MM-DD HH:mm') : null,
            'keysCollected': false,
            'keysCollectedDate': null
        };

        dbUpdate(`rentals/${uniqueId}`, newValue);

        setRentals(() => {
            return {
                ...rentals,
                [uniqueId]: newValue
            }
        });
        markAsUnavailable(newValue.car);
    };
    const toggleKeysCollected = (uniqueId) => {

        if (!rentals[uniqueId].approved) return;

        const keysCollected = (rentals[uniqueId].keysCollected != null) ? !rentals[uniqueId].keysCollected : true;
        const newValue = {
            ...rentals[uniqueId],
            'keysCollected': keysCollected,
            'keysCollectedDate': keysCollected ? moment(new Date()).format('YYYY-MM-DD HH:mm') : null,
        };

        dbUpdate(`rentals/${uniqueId}`, newValue);
        setRentals(() => {
            return {
                ...rentals,
                [uniqueId]: newValue
            }
        });

    };

    const toggleCompleted = (uniqueId) => {

        if (!rentals[uniqueId].approved && !rentals[uniqueId].keysCollected) return;

        const completed = (rentals[uniqueId].completed != null) ? !rentals[uniqueId].completed : true;
        const newValue = {
            ...rentals[uniqueId],
            'completed': completed,
            'completedDate': completed ? moment(new Date()).format('YYYY-MM-DD HH:mm') : null,
        };

        dbUpdate(`rentals/${uniqueId}`, newValue);
        setRentals(() => {
            return {
                ...rentals,
                [uniqueId]: newValue
            }
        });

    };

    const markAsUnavailable = (id) => {

        const available = false;
        const newValue = {
            ...cars[id],
            'available': available
        };

        dbUpdate(`cars/${id}`, newValue);

        setCars(prevValue => {
            const newCarList = [...prevValue];
            newCarList[id] = newValue;
            return newCarList
        });
        console.log(cars);
    };

    const deleteEntry = (uniqueId, carId) => {

        dbDelete(`rentals/${uniqueId}`);
        dbDelete(`cars/${carId}/bookings/${uniqueId}`);
    }

    const showLocationTextOrMap = (locationText) => {

        const coordinates = locationText.split(',');

        const url = `https://www.google.com/maps/search/?api=1&query=${locationText}`;


        if (coordinates.length !== 2 || isNaN(coordinates[0]) || isNaN(coordinates[1])) {
            return <a href={url} target="_blank" rel="noreferrer"> {locationText} </a>
        }

        // valid coordinates 
        return <a href={url} target="_blank" rel="noreferrer"> 
                    <LocationOnOutlinedIcon fontSize="small"/> Map  
                </a>
    };

    return (
        <>
            <div className={classes.RContainer} >
                <h2> Rentals</h2>
                <TableContainer component={Paper} sx={{ borderRadius: 3, padding: 3 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell> Customer <BadgeIcon /></TableCell>
                                <TableCell > Booking Details <DateRangeIcon /> </TableCell>
                                <TableCell align="center">Approved <BeenhereIcon /> </TableCell>
                                <TableCell align="center">Collected <KeyIcon /></TableCell>
                                <TableCell >Completed <FactCheckIcon /> </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                Object.keys(rentals)?.map((uniqueId) => (
                                    (!rentals[uniqueId].completed) && <TableRow
                                        className="fRow"
                                        key={uniqueId}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>
                                            <IconButton aria-label="delete" size="small" onClick={() => deleteEntry(uniqueId, rentals[uniqueId].car)}>
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </TableCell>

                                        <TableCell component="th" scope="row" >
                                            {rentals[uniqueId].name} <br />
                                            {rentals[uniqueId].phone} <br />
                                            {rentals[uniqueId].email} <br />
                                            {rentals[uniqueId].id} 
                                            {
                                                (rentals[uniqueId].eftRef) && 
                                                <span><br /> EFT REF: {rentals[uniqueId].eftRef}</span> 
                                            }
                                            {
                                                (rentals[uniqueId].events) && <span style={{ color: '#3f51b5' }}><br />
                                                    <CheckIcon fontSize="small"/> {rentals[uniqueId].events}</span>
                                            }
                                            
                                            {
                                                (rentals[uniqueId].chauffeur) && 
                                                <span style={{ color: '#3f51b5' }}> <br />
                                                    <CheckIcon fontSize="small"/> Chauffeur
                                                    </span> 
                                            }

                                            
                                        </TableCell>

                                        <TableCell >
                                            Car: {cars[rentals[uniqueId].car]?.name}, {cars[rentals[uniqueId].car]?.color}
                                            , {cars[rentals[uniqueId].car]?.registration}<br />
                                            Pickup: {showLocationTextOrMap(rentals[uniqueId].pickupLocation)} <br />
                                            <span style={{ color: 'red' }}>{rentals[uniqueId].pickupDate} </span> <br />

                                            DropOff: {(rentals[uniqueId].dropOffLocation === rentals[uniqueId].pickupLocation)?
                                                "Same Location" : 
                                             showLocationTextOrMap(rentals[uniqueId].dropOffLocation)} <br />
                                            <span style={{ color: 'green' }}>{rentals[uniqueId].dropoffDate}</span><br />
                                            Total Days: {rentals[uniqueId].totalDays}
                                        </TableCell>
                                      

                                        <TableCell align="center">
                                            <Switch checked={rentals[uniqueId].approved} onChange={() => approveRental(uniqueId)} />
                                            <br />
                                            {rentals[uniqueId].approvedDate}
                                        </TableCell>

                                        <TableCell align="center">
                                            <Switch checked={rentals[uniqueId].keysCollected} onChange={() => toggleKeysCollected(uniqueId)} />
                                            <br />
                                            {rentals[uniqueId].keysCollectedDate}
                                        </TableCell>


                                        <TableCell align="center">
                                            <Switch checked={rentals[uniqueId].completed} onChange={() => toggleCompleted(uniqueId)} />
                                            <br />
                                            {rentals[uniqueId].completedDate}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            <div className={classes.RContainer} style={{ marginTop: 30 }}>
                <h2> Completed Rentals</h2>
                <TableContainer component={Paper} sx={{ borderRadius: 3, padding: 3 }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell><BadgeIcon /> Customer </TableCell>
                                <TableCell > Booking Details <DateRangeIcon /> </TableCell>
                                <TableCell align="center">Approved <BeenhereIcon /> </TableCell>
                                <TableCell align="center">Collected <KeyIcon /></TableCell>
                                <TableCell >Completed <FactCheckIcon /> </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                Object.keys(rentals)?.map((uniqueId) => (
                                    (rentals[uniqueId].completed) && <TableRow
                                        className="fRow"
                                        key={uniqueId}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell>
                                            <IconButton aria-label="delete" size="small" onClick={() => deleteEntry(uniqueId, rentals[uniqueId].car)}>
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        </TableCell>

                                        <TableCell component="th" scope="row" >
                                            {rentals[uniqueId].name} <br />
                                            {rentals[uniqueId].phone} <br />
                                            {rentals[uniqueId].email} <br />
                                            {rentals[uniqueId].id}
                                            {
                                                (rentals[uniqueId].eftRef) && 
                                                <span><br /> EFT REF: {rentals[uniqueId].eftRef}</span> 
                                            }
                                            {
                                                (rentals[uniqueId].events) && <span style={{ color: '#3f51b5' }}><br />
                                                    <CheckIcon fontSize="small"/> {rentals[uniqueId].events}</span>
                                            }
                                            
                                            {
                                                (rentals[uniqueId].chauffeur) && 
                                                <span style={{ color: '#3f51b5' }}> <br />
                                                    <CheckIcon fontSize="small"/> Chauffeur
                                                    </span> 
                                            }
                                        </TableCell>

                                        <TableCell >
                                            Car: {cars[rentals[uniqueId].car]?.name}, {cars[rentals[uniqueId].car]?.color}
                                            , {cars[rentals[uniqueId].car]?.registration}<br />
                                            Pickup: {rentals[uniqueId].pickupLocation} <br />
                                            <span style={{ color: 'red' }}>{rentals[uniqueId].pickupDate} </span> <br />

                                            DropOff: {rentals[uniqueId].dropOffLocation} <br />
                                            <span style={{ color: 'green' }}>{rentals[uniqueId].dropoffDate}</span>
                                        </TableCell>
                    

                                        <TableCell align="center">
                                            <Switch checked={rentals[uniqueId].approved} onChange={() => approveRental(uniqueId)} />
                                            <br />
                                            {rentals[uniqueId].approvedDate}
                                        </TableCell>

                                        <TableCell align="center">
                                            <Switch checked={rentals[uniqueId].keysCollected} onChange={() => toggleKeysCollected(uniqueId)} />
                                            <br />
                                            {rentals[uniqueId].keysCollectedDate}
                                        </TableCell>


                                        <TableCell align="center">
                                            <Switch checked={rentals[uniqueId].completed} onChange={() => toggleCompleted(uniqueId)} />
                                            <br />
                                            {rentals[uniqueId].completedDate}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </>

    )
}