import React from "react";
import moment from 'moment';
import LogNavbar from "./Navbars/LogNavbar.js";
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import classes from '../views/Rentals.module.css';



export default function HurricanePage({ children }) {
   
    return (
        <div> 
            <LogNavbar />
            <div className="page-header clear-filter" filter-color="blue" style={{ textAlign: 'center'}}>
                <div className="page-header-image"
                    style={{ backgroundImage: "url(" + require("assets/img/header.jpg").default + ")", }}></div>
                    <div style={{ minHeight: 'calc(100vh - 171.5px)', marginTop: 130}}>
                    { children }
                    </div>

                    <div style={{padding: '10px', color: 'white', textAlign: 'center'}}> © Designed by &nbsp;
                    <a href="https://sology.net" target="_blank" rel="noreferrer">
                        <img alt="..." className="invision-logo" src="https://sology.net/src/logo/sology4.png" height="20"
                        style={{ cursor: 'pointer'}}/>
                    </a>
                </div>
                

            </div>
          
        </div>
        
    )
}
