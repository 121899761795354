import React from 'react';
import classNames from 'classnames';
import classes from './booking.module.css';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SmallBookingButton from './booking-button-small.js';
import BigBookingButton from './booking-button-big.js';
import DatePickerButton from './datepicker-button.js';
import SearchLocationInput from './location.js';
import CircularBtn from './circular-button.js';
import SearchIcon from '@mui/icons-material/Search';
import moment from 'moment';
import { dbSave, dbListenTo } from '../firebase-config.js';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

export default function Booking({ setRentDetails }) {

    const pickupDefaultDate = moment(new Date()).add('minutes', 30);
    const dropOffDefaultDate = moment(new Date()).add('days', 1);

    const [pickupLocation, setPickupLocation] = React.useState("");
    const [dropOffLocation, setDropOffLocation] = React.useState("");
    const [pickupDate, setPickupDate] = React.useState(pickupDefaultDate.format('YYYY-MM-DD HH:mm'));
    const [dropoffDate, setDropoffDate] = React.useState(dropOffDefaultDate.format('YYYY-MM-DD HH:mm'));

    let history = useHistory();


    const doBooking = () => {

        if(!pickupLocation) {
            toast.error('Please a valid pickup location');
            return;
        }


        const date1 = moment(pickupDate);
        const date2 = moment(dropoffDate);
        const diff = date2.diff(date1, 'hours');

        if(!pickupDate || (new Date(pickupDate) < new Date()) ||
            !dropoffDate || (new Date(dropoffDate) <= new Date(pickupDate)) ||
            diff < 1
        ) {
            toast.error('Please enter valid dates');
            return;
        }
        setRentDetails({
            pickupLocation,
            dropOffLocation: (dropOffLocation || pickupLocation),
            pickupDate: date1.format('YYYY-MM-DD HH:mm'),
            dropoffDate: date2.format('YYYY-MM-DD HH:mm'),
            totalDays: Math.ceil(diff/24)
        });
      
        history.push('/search');

    };
    const [diffDropOff, setDiffDropoff] = React.useState(false);
    
    const handleDropOff = () => {
        setDiffDropoff(false);
       
    }
    return (

        <div className={classes.bookingContainer}>
            <div>
                <SmallBookingButton selected={!diffDropOff} label='Same Drop Off' onClick={handleDropOff} />
                <SmallBookingButton selected={diffDropOff} label='Different Drop Off' onClick={() => setDiffDropoff(true)} />
            </div>
            <div>
                <div>
                    {
                        (diffDropOff) && <BigBookingButton label="Location" hint={<>
                            <SearchLocationInput key="1" identifier="pickup" setLocation={setPickupLocation} placeholder="Pick up address"
                             />
                            <SearchLocationInput key="2" identifier="dropoff" setLocation={setDropOffLocation} placeholder="Drop off address"
                                style={{ marginTop: 10 }} /></>}
                            icon={<LocationOnIcon fontSize="large" />} ultraWidth={true} />
                    }
                    {
                        (!diffDropOff) && <BigBookingButton label="Location"
                            hint={<SearchLocationInput identifier="pickup2" setLocation={setPickupLocation} placeholder="Pick up address" />
                            } icon={<LocationOnIcon fontSize="large" />} ultraWidth={true} />
                           
                            
                    }
      
                </div>

                <div style={{ marginTop: 30 }}>
                    <DatePickerButton label="Pick Up" defaultDate={pickupDefaultDate} setDate={setPickupDate} />
                    <DatePickerButton label="Drop Off" defaultDate={dropOffDefaultDate} setDate={setDropoffDate} />
                </div>

                <div className={classes.searchBtnContainer}>

                    <CircularBtn icon={<SearchIcon fontSize='large' />} onClick={doBooking} />

                </div>
                
            </div>

            {/* 
            <div className={classes.searchBtn}>

            <img
              alt="..."
              src={require("assets/img/logo1.png").default}
              width="88%"
            ></img>
            </div> */}
        </div>
    );
}

