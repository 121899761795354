import React from "react";
import LogNavbar from "components/Navbars/LogNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import Rentals from "./Rentals";
import Cars from "./Cars";
import HurricanePage from "components/HurricanePage.js";

export default function Dashboard_Content() {
    return (

        <HurricanePage>
                <Rentals/>
                <Cars/>
        </HurricanePage>
      
    )
}