import React from "react";

export default function Payment({ rentDetails }) {

    return (

        <div>
            <a href={rentDetails.paymentUrl}> Click me </a>

        </div>
    )
}
