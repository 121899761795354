import React from "react";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
} from "reactstrap";
import LogNavbar from "components/Navbars/LogNavbar.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import KeyIcon from '@mui/icons-material/Key';
import EmailIcon from '@mui/icons-material/Email';
import { getAuth, signInWithEmailAndPassword} from 'firebase/auth';
import HurricanePage from "components/HurricanePage.js";


function LoginPage({ setLoggedIn }) {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
 
  React.useEffect(() => {
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);


  const handleLogin = () => {
    const authentication = getAuth();
    signInWithEmailAndPassword(authentication, email, password)
    .then((response) => {
   setLoggedIn(true) 
       sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken) })

    .catch((error) => {
      if(error.code === 'auth/wrong-password'){
        toast.error('Please check your password');
      }
      if(error.code === 'auth/user-not-found'){
        toast.error('Please check your email');
      }

    })
  }

  
  return (
    <HurricanePage>

        <div className="content">
          <Container>
            <Col className="ml-auto mr-auto" md="4">
              <Card className="card-login card-plain">
                <Form action="" className="form" method="" >
                  <CardHeader className="text-center">
                    <div className="logo-container">
                      {/* <img alt="..." src="/static/media/logo3.efb49237.png" >
                      </img> */}
                    </div>
                  </CardHeader>
                  <CardBody>
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (firstFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon  addonType="prepend">
                        <InputGroupText>
                          <EmailIcon />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Email"
                        type="text"
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      ></Input>
                    </InputGroup>
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (lastFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon  addonType="prepend">
                        <InputGroupText >
                          <KeyIcon />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Password"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onFocus={() => setLastFocus(true)}
                        onBlur={() => setLastFocus(false)}
                      ></Input>
                    </InputGroup>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      block
                      className="btn-round"
                      color="info"
                      onClick={handleLogin}
                      size="lg">
                      Log In
                    </Button>
                    <ToastContainer style={{top:130}} />
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Container>
        </div>
    </HurricanePage>
  );
}

export default LoginPage;
