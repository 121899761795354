import React from "react";
import { dbSave, dbListenTo } from '../firebase-config.js';
import CarCard from "components/car-card.js";
import HurricanePage from "components/HurricanePage.js";
import GlossyMessage from "components/GlossyMessage.js";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

function Search({ rentDetails, setRentDetails }) {

  const [availableCarsCount, setAvailableCarsCount] = React.useState(-1);
  const [cars, setCars] = React.useState([]);

  if(!rentDetails.pickupLocation) window.location = '/';

  React.useEffect(() => {

    let availableCarCounter = 0;
    dbListenTo('cars', (data) => {
      if(!data) {
        setCars([]);
        return;
      }
      
      data.forEach(car => {
        
        car.availableWithinPeriod = isCarAvailableOnThisPeriod(car);
        if(car.availableWithinPeriod && car.available) availableCarCounter++;
      });
      setAvailableCarsCount(availableCarCounter);
      
      setCars(data);
    });

  }, []);


  function isCarAvailableOnThisPeriod(car){

    if(!car.bookings || Object.keys(car.bookings).length === 0) return true;

    let available = true;

    Object.keys(car.bookings).forEach(key => {
        const booking = car.bookings[key];
        

        if ((new Date(booking.from) <= new Date(rentDetails.pickupDate)) &&
            (new Date(rentDetails.pickupDate) <= new Date(booking.to))){
          available = false
        }
        else if ((new Date(booking.from) <= new Date(rentDetails.dropoffDate)) &&
        (new Date(rentDetails.dropoffDate) <= new Date(booking.to))){
          available = false
        }
    });
    return available;
  }

  return (
    <HurricanePage>
        
      {
        (availableCarsCount === -1 ) 
          &&  (<Box sx={{ width: '100%', padding: '5% 30%' }}><LinearProgress /></Box>)
      }
      {
        (availableCarsCount === 0 ) 
          && <GlossyMessage message="Sorry, we can't find any available cars for the selected period. 
          Please contact us to verify." type='info'/> 
      }
      {
        cars.map((item, index) =>

          (item.available && item.availableWithinPeriod) && 
            <CarCard key={index} carId={index} name={item.name} price={item.price} image={item.image}
             setRentDetails={setRentDetails} rentDetails={rentDetails} specs={item.specs}
            />
        )
      }
    </HurricanePage>
  );
}

export default Search;
