import React from "react";
import moment from 'moment';
import HurricanePage from "components/HurricanePage.js";
import HurricaneGlossyWindow from "components/HurricaneGlossyWindow.js";
import LogNavbar from "components/Navbars/LogNavbar.js";
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import classes from '../views/Rentals.module.css';
import { dbSave, dbListenTo } from '../firebase-config.js';
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import payment from "libraries/payment.js";


const Submit = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#be9d50',
    borderColor: '#be9d50',
    '&:hover': {
        backgroundColor: '#dfdf95',
        borderColor: '#dfdf95',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#e3e6a4',
        borderColor: '#9a6b2f',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem #dfdf95',
    },
});

export default function Form({ rentDetails, setRentDetails }) {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [customerDetails, setCustomerDetails] = React.useState({
        name: "",
        email: "",
        phone: "",
        id: "",
        license: ""
    })


    let history = useHistory();

    function handleChange(ev) {
        const v = ev.target.value;
        const name = ev.target.name;

        setCustomerDetails(
            preValue => {
                return {
                    ...preValue,
                    [name]: v
                }
            }
        );
    }

    const pay = async () => {

        const uniqueId = moment(new Date()).format('YYYYMMDDHHmmssSS');

        const allRentDetails = Object.assign(customerDetails, rentDetails);

        // allRentDetails.paymentUrl = await payment(rentDetails.total);
        allRentDetails.id = uniqueId;
        allRentDetails.eftRef = 'H' + uniqueId.substring(8);

        setRentDetails(allRentDetails);

        dbSave(`rentals/${uniqueId}`, allRentDetails);
        dbSave(`cars/${rentDetails.car}/bookings/${uniqueId}`, { from: rentDetails.pickupDate, to: rentDetails.dropoffDate });

        // history.push('/SuccessPaymentMessage');
        history.push('/EftPayment');
        
        // setTimeout(()=> history.push('/SecurePayment'), 3000);
        
    };

    return (
        <HurricanePage>
            <HurricaneGlossyWindow message="Please enter your details ">
                <div>
                    <FormControl className={classes.formC}>
                        <InputLabel htmlFor="my-input" className={classes.formInput}>Full Name</InputLabel>
                        <Input name="name" 
                             aria-describedby="my-helper-text" sx={{color:'white', paddingLeft:2}}
                            type="text"  {...register("name", { onChange: e => handleChange(e), required: true, pattern: /^[A-Z a-z]+$/i })} />
                        {errors.name && <p style={{ color: '#ba000d', fontSize: 12, fontWeight: 'bold', textAlign: 'left' }}>invaild name</p>}

                    </FormControl>

                    <FormControl className={classes.formC}>
                        <InputLabel htmlFor="my-input" className={classes.formInput}>Email</InputLabel>
                        <Input name="email" aria-describedby="my-helper-text" type="email" sx={{color:'white', paddingLeft:2}}
                         {...register("email", { onChange: e => handleChange(e), required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
                        {errors.email && <p style={{ color: '#ba000d', fontSize: 12, fontWeight: 'bold', textAlign: 'left' }}>invaild email address</p>}
                    </FormControl>
                </div>

                <div >


                    <FormControl className={classes.formC}>
                        <InputLabel htmlFor="my-input" className={classes.formInput}>Cell phone</InputLabel>
                        <Input name="phone" aria-describedby="my-helper-text" type="tel" sx={{color:'white', paddingLeft:2}}
                        {...register("phone", {  onChange: e => handleChange(e), required: true, pattern: /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im })} />
                        {errors.phone && <p style={{ color: '#ba000d', fontSize: 12, fontWeight: 'bold', textAlign: 'left' }}>invaild phone number</p>}
                    </FormControl>

                    <FormControl className={classes.formC}>
                        <InputLabel htmlFor="my-input" className={classes.formInput}>ID number</InputLabel>
                        <Input name="id" aria-describedby="my-helper-text" type="number"  sx={{color:'white', paddingLeft:2}}
                        {...register("id", { onChange: e => handleChange(e), required: true, minLength: 5, maxLength: 20 })} />
                        {errors.id && <p style={{ color: '#ba000d', fontSize: 12, fontWeight: 'bold', textAlign: 'left' }}>invaild ID number</p>}
                    </FormControl>
                </div>

                <div>
                    <FormControl className={classes.formC}>
                        <InputLabel htmlFor="my-input" className={classes.formInput}>Driver license</InputLabel>
                        <Input name="license" aria-describedby="my-helper-text" type="number" sx={{color:'white', paddingLeft:2}}
                         {...register("license", { onChange: e => handleChange(e), required: true, minLength: 5, maxLength: 20 })} />
                        {errors.license && <p style={{ color: '#ba000d', fontSize: 12, fontWeight: 'bold', textAlign: 'left' }}>invaild license number</p>}
                    </FormControl>
                </div>
                <div>
                    <Submit onClick={handleSubmit(pay)} variant="contained" disableRipple>
                        Continue
                    </Submit>
                </div>
            </HurricaneGlossyWindow>
        </HurricanePage>
    )
}
