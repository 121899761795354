import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function Images() {
  return (
    <>
      <div className="section section-images">
        <Container style={{marginTop: '12em'}}>
          <Row>
            <Col md="12">
              <div className="hero-images-container">
                <img
                  alt="..."
                  src={require("assets/img/suv.png").default}
                ></img>
              </div>
              <div className="hero-images-container-1">
                <img
                  alt="..."
                  src={require("assets/img/cars/car2.png").default}
                ></img>
              </div>
              {/* <div className="hero-images-container-2">
                <img
                  alt="..."
                  src={require("assets/img/car4.png").default}
                ></img>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Images;
