/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function DarkFooter() {
  return (
    <footer className="footer" 
    // data-background-color="black"
    >
      <Container>
        {/* <nav>
          <ul>
            <li>
              <a
                href="https://www.creative-tim.com?ref=nukr-dark-footer"
                target="_blank"
              >
                Creative Tim
              </a>
            </li>
            <li>
              <a
                href="http://presentation.creative-tim.com?ref=nukr-dark-footer"
                target="_blank"
              >
                About Us
              </a>
            </li>
            <li>
              <a
                href="http://blog.creative-tim.com?ref=nukr-dark-footer"
                target="_blank"
              >
                Blog
              </a>
            </li>
          </ul>
        </nav> */}
        <div className="copyright" id="copyright">
          © {new Date().getFullYear()}, Designed by &nbsp;
          <a href="https://sology.net" target="_blank">
              <img
                alt="..."
                className="invision-logo"
                src="https://sology.net/src/logo/sology4.png"
                height={20}
                style={{verticalAlign: '-9px', cursor: 'pointer'}}
              ></img>
            </a>          
        </div>
      </Container>
    </footer>
  );
}

export default DarkFooter;
