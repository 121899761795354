import React from "react";
import classes from '../Rentals.module.css';
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ScienceIcon from '@mui/icons-material/Science';
import SecurityIcon from '@mui/icons-material/Security';
import CelebrationIcon from '@mui/icons-material/Celebration';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import DiscountIcon from '@mui/icons-material/Discount';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import LocalConvenienceStoreIcon from '@mui/icons-material/LocalConvenienceStore';
import { createTheme } from '@mui/material/styles';
import Divider from '@mui/material/Divider';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0971f1',
      darker: '#053e85',
    },
    neutral: {
      main: '#64748B',
      contrastText: '#fff',
    },
  },
});
// core components

function WhyUs() {
  const [iconPills, setIconPills] = React.useState("1");
  const [pills, setPills] = React.useState("1");
  return (
    <>
      <div className="section section-tabs"  >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="10" xl="12" style={{ textAlign: 'center' }}>

              <Card className={classes.tabContainer} style={{ borderRadius: '8px', maxWidth: '630px' , margin: 'auto' }}>
                <CardHeader>
                  <p className="category"
                    style={{ textAlign: 'center',color:"#c1a456", marginTop: 25, fontSize: 25 }}>Why Hurricane </p>
                </CardHeader>
              
                <div style={{textAlign:'center'}}>
                    <Divider sx={{ bgcolor: "#c1a456", width:"80%",margin:'auto'}}/>
                    </div>

                <CardBody style={{color:'white', fontSize: 30}}>
                  <List>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <ScienceIcon style={{color:'white'}}/>
                        </ListItemIcon>
                        <ListItemText primary="Get The best car rental experience" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <SecurityIcon style={{color:'white'}}/>
                        </ListItemIcon>
                        <ListItemText primary="Stay protected with our exclusive armored vehicle" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <CelebrationIcon style={{color:'white'}}/>
                        </ListItemIcon>
                        <ListItemText primary="Show up to your event in class" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <ElectricCarIcon style={{color:'white'}}/>
                        </ListItemIcon>
                        <ListItemText primary="Ride in style in our luxury cars" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <DiscountIcon style={{color:'white'}}/>
                        </ListItemIcon>
                        <ListItemText primary="Greatest deals on budget cars" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <PersonPinCircleIcon style={{color:'white'}}/>
                        </ListItemIcon>
                        <ListItemText primary="Point to point sale services: we will come to you" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <LocalConvenienceStoreIcon style={{color:'white'}}/>
                        </ListItemIcon>
                        <ListItemText primary="24/7 customer support" />
                      </ListItemButton>
                    </ListItem>
                  </List>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

    </>
  );
}

export default WhyUs;
