import React from 'react';
import classNames from 'classnames';
import classes from './booking-button-big.module.css';

export default function BigBookingButton({ icon, label, hint, onClick, ultraWidth}){
    

    const cl = classNames(classes.bigBookingButton, {[classes.ultraWidth]: ultraWidth});

    return (

        <div className={cl} onClick={onClick}>
            
            <div>{icon}</div>
            <div>
                <div className={classes.mainText} >{label}</div>
                <div className={classes.subText}>{hint}</div>
            </div>
        </div>

    );
}