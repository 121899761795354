import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyIcon from '@mui/icons-material/Key';
import classes from './car-card.module.css';
import { styled } from '@mui/material/styles';
import { useHistory } from "react-router-dom";
// import MenuItem from '@mui/material/MenuItem';
// import Switch from '@mui/material/Switch';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Select from '@mui/material/Select';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
// import TextSwitch from './text-switch';
import DropdownButton from './DropdownButton';
import OnOffButton from './OnOffButton';
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import ButtonGroup from '@mui/material/ButtonGroup';
import SensorDoorOutlinedIcon from '@mui/icons-material/SensorDoorOutlined';
import AirlineSeatReclineExtraTwoToneIcon from '@mui/icons-material/AirlineSeatReclineExtraTwoTone';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';

const Getkeys = styled(Button)({
  boxShadow: 'none',
  textTransform: 'none',
  fontSize: 16,
  padding: '6px 12px',
  border: '1px solid',
  lineHeight: 1.5,
  backgroundColor: '#be9d50',
  borderColor: '#be9d50',
  width: '100%',
  '&:hover': {
    backgroundColor: '#dfdf95',
    borderColor: '#dfdf95',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#e3e6a4',
    borderColor: '#9a6b2f',
  },
  '&:focus': {
    boxShadow: '0 0 0 0.2rem #dfdf95',
  },
});

export default function CarCard({ carId, name, price, image, rentDetails, setRentDetails, specs}) {
  const {doors,seats,gear,bulletproof,fuel} = specs;
  const history = useHistory();
  const [rentEvent, setRentEvent] = React.useState('');

  const discountPercentage = (rentDetails.totalDays >= 7)? 0.3 : 0; // 30%
  let defaultTotal = price * rentDetails.totalDays;
  defaultTotal -= (defaultTotal * discountPercentage)

  const[totalPrice,setTotalPrice]=React.useState(defaultTotal);
  const[chauffeur,setChauffeur]=React.useState(false);

  const chaufferValue = 500;
  const eventValue = 2000;

  const handleEvents = (eventName) => {
    setRentEvent(eventName);
    let lowCaseEvent = eventName.toLowerCase();

    if(eventName && lowCaseEvent !== 'none' && lowCaseEvent !== 'event') {
      setTotalPrice(totalPrice + eventValue);
    }
    else {
      setTotalPrice(totalPrice - eventValue);
    }

  }

  function handelChauffeur(isOn) {
    
    // const chaufferAmount = (chaufferValue * rentDetails.totalDays);
    // if(isOn) { 
    //   setTotalPrice(totalPrice + chaufferAmount);
    // }
    // else {
    //   setTotalPrice(totalPrice - chaufferAmount);
    // }
    setChauffeur(isOn);
  }

  const getKeysClick = (selectedCar) => {
    
    setRentDetails(
      prevValue => {
        return {
          ...prevValue,
          "car": selectedCar,
          "events": rentEvent,
          "chauffeur": chauffeur,
          "total": totalPrice
        };
      }
    );

    history.push('/form');
  }


  let eventOptions = [
    'Event', 'None', 'Matric ball','Wedding','Graduation','Photo Shoot','Other'
  ];

  function formatAmount(amount){

    let priceText = 'Unknown';

    if (amount && amount > 0) priceText = `R${parseFloat(amount).toLocaleString('en')}`;

    return priceText;
  }
  return (
    <div className={classes.RContainer}>

      <div className={classes.dayPrice}>
        {rentDetails.totalDays} days X {formatAmount(price)}
        <sup> Per Day</sup>
      </div>

      { 
        (rentDetails.totalDays >= 7) && 
          <div className={classes.discountTag}>
          -30%
        </div>
      } 

      <CardMedia
        component="img"
        className={classes.cardMedia}
        image={require(`assets/img/cars/${image}`)?.default}

        alt="Live from space album cover"
      />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <CardContent sx={{ flex: '1 0 auto', padding: '15px 0 0 0' }}>
          <Typography component="div" variant="h5">
            {name}
          </Typography>

          <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                '& > *': {
                  m: 1,
                },
              }}
            >

              <div className={classes.specsContainer}>
                <div className={classes.specsItem} ><SensorDoorOutlinedIcon fontSize="small" /><span>{doors}</span></div>
                <div className={classes.specsItem} ><AirlineSeatReclineExtraTwoToneIcon fontSize="small" /><span>{seats}</span></div>
                <div className={classes.specsItem} ><LocalGasStationOutlinedIcon fontSize="small" /><span>{fuel.substring(0,1)}</span></div>
                <div className={classes.specsItem} ><SettingsOutlinedIcon fontSize="small" /><span>{gear}</span></div>
                {
                  bulletproof && 
                  <div className={classes.specsItem} ><SecurityOutlinedIcon fontSize="small" /><span>Bulletproof</span></div>
                }
              </div>
            </Box>
          <div style={{ margin: '0'}}>

            <DropdownButton options={eventOptions} onChange={handleEvents} />
            <OnOffButton label="Chauffeur" icon={<AccessibilityNewIcon/>} onChange={handelChauffeur}/>

            <div className={classes.carFooter}>
            <Getkeys variant="contained" disableRipple endIcon={<KeyIcon fontSize='large' />}
              onClick={() => getKeysClick(carId)}>
                Total: {formatAmount(totalPrice)}, 
                Get The Keys 
            </Getkeys>
            </div>

            

          </div>

        </CardContent>




      </Box>

    </div>
  );
}

