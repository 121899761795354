/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import Booking from "../booking.js";
// core components

function IndexHeader({ setRentDetails }) {
  let pageHeader = React.createRef();

  React.useEffect(() => {

    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }

  });

  return (
    <>
    {/* <div className="page-header clear-filter" filter-color="blue"></div> */}
      <div className="page-header " >

        <Container>
          <div className="content-center brand">
            {/* <img
              alt="..."
              src={require("assets/img/logo1.png").default}
              width={500}
            ></img> */}

            <h1 className="h1-seo" style={{color:"#c1a456"}}>Hurricane Car Rentals</h1>
            <h3 style={{color:"#c1a456"}}>The best car rental experience</h3>
          </div>
          <div style={{ display: 'inline-block' }}>


            <img
              style={{ margin: 'auto', marginTop: '-5em', opacity: 0.79, filter: 'grayscale(1)', width: '90%', maxWidth: '69vh' }}  // sepia(1)
              alt="..."
              src={require('assets/img/luxuary-car2.png').default}
            />

          </div>

          <Booking setRentDetails={setRentDetails} />

          {/* <h6 className="category category-absolute">
            Designed by &nbsp;
            <a href="https://sology.net" target="_blank">
              <img
                alt="..."
                className="invision-logo"
                src="https://sology.net/src/logo/sology4.png"
                height={20}
              ></img>
            </a>
            
          </h6> */}
        </Container>
      </div>
    </>
  );
}

export default IndexHeader;
