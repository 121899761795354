import React from "react";
import classes from './HurricaneGlossyWindow.module.css';


export default function HurricaneGlossyWindow({message, children}) {

    return (
            <div className={classes.RContainer} style={{textAlign: 'center'}}>
                <h3 style={{color:"#f1eebff8"}}> {message} </h3>

                {children}
               
            </div>
    )
}
