import React from "react";
import classes from '../Rentals.module.css';
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";
import CelebrationIcon from '@mui/icons-material/Celebration';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import LocalAirportIcon from '@mui/icons-material/LocalAirport';
import StarsIcon from '@mui/icons-material/Stars';
import { CenterFocusStrong, MarginOutlined } from "@mui/icons-material";
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ManIcon from '@mui/icons-material/Man';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';

// core components

function Tabs() {
  const [iconPills, setIconPills] = React.useState("1");
  const [pills, setPills] = React.useState("1");
  return (
    <>
      <div className="section section-tabs" style={{ textAlign: "center" }} >

        <Row >
          <Col className="ml-auto mr-auto" md="10" xl="12">

            <Card className={classes.tabContainer} style={{ borderRadius: '8px' }}>
              <CardHeader>
                <p className="category"
                  style={{ textAlign: 'center', color: "#c1a456", marginTop: 25, fontSize: 25 }}>What we offer!
                </p>
                <div style={{ textAlign: 'center' }}>
                  <Divider sx={{ bgcolor: "#c1a456", width: "80%", margin: 'auto' }} />
                </div>
              </CardHeader>


              <CardBody className={classes.tabHide} >
                <Nav className="justify-content-center" role="tablist" tabs>

                  <NavItem>
                    <NavLink
                      className={iconPills === "1" ? "active" : ""}
                      style={{ color: "white" }}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setIconPills("1");
                      }}
                    >
                      <CelebrationIcon /> &nbsp;
                      Events
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={iconPills === "2" ? "active" : ""}
                      style={{ color: "white" }}
                      href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        setIconPills("2");
                      }}
                    >
                      <LocalAirportIcon /> &nbsp;
                      Airport pick ups and drop off
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={iconPills === "3" ? "active" : ""}
                      href="#pablo"
                      style={{ color: "white" }}
                      onClick={(e) => {
                        e.preventDefault();
                        setIconPills("3");
                      }}
                    >
                      <StarsIcon /> &nbsp;
                      VIP Escort
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={iconPills === "4" ? "active" : ""}
                      href="#pablo"
                      style={{ color: "white" }}
                      onClick={(e) => {
                        e.preventDefault();
                        setIconPills("4");
                      }}
                    >
                      <DirectionsCarIcon /> &nbsp;
                      Vehicles
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent
                  className="text-center"
                  activeTab={"iconPills" + iconPills}
                >
                  <TabPane tabId="iconPills1" style={{ textAlign: 'center' }}>
                    <List >
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <MilitaryTechIcon style={{ color: 'white' }} />
                          </ListItemIcon>
                          <ListItemText style={{ color: 'white' }} primary="Matric ball" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <CelebrationIcon style={{ color: 'white' }} />
                          </ListItemIcon>
                          <ListItemText style={{ color: 'white' }} primary="Weddings" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <CameraAltIcon style={{ color: 'white' }} />
                          </ListItemIcon>
                          <ListItemText style={{ color: 'white' }} primary="Photo shoot / videos" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <CelebrationIcon style={{ color: 'white' }} />
                          </ListItemIcon>
                          <ListItemText style={{ color: 'white' }} primary="Any other occasion of your choice! " />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <ManIcon style={{ color: 'white' }} />
                          </ListItemIcon>
                          <ListItemText style={{ color: 'white' }} primary=" We also offer a chauffeur at an extra cost " />
                        </ListItemButton>
                      </ListItem>
                    </List>


                  </TabPane>
                  <TabPane tabId="iconPills2" style={{ textAlign: 'center', color: 'white' }}>
                    <p>
                      If you are travelling, you don't have to worry. We got you covered!
                    </p>
                    <p>
                      Book a car and we will deliver it to your location!
                    </p>
                  </TabPane>
                  <TabPane tabId="iconPills3"  >
                    <List >
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <MilitaryTechIcon style={{ color: 'white' }} />
                          </ListItemIcon>
                          <ListItemText style={{ color: 'white' }} primary="Bullet Proof vehicles" />
                        </ListItemButton>
                      </ListItem>
                      <ListItem disablePadding>
                        <ListItemButton>
                          <ListItemIcon>
                            <ManIcon style={{ color: 'white' }} />
                          </ListItemIcon>
                          <ListItemText style={{ color: 'white' }} primary="Chauffeur services" />
                        </ListItemButton>
                      </ListItem>
                    </List>

                  </TabPane>
                  <TabPane tabId="iconPills4" style={{ textAlign: 'center', color: 'white' }}>
                    <p>
                      We have a variety of cars and options that you can choose whats works the best for you!
                    </p>
                  </TabPane>
                </TabContent>
              </CardBody>


              <TreeView
                className={classes.tabShow}
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
                sx={{flexGrow: 1,
                 color: "white",paddingTop:"30px" , textAlign:"center"}}
              >
                <TreeItem nodeId="1" label= "Events" className={classes.treeItem}>
                  <List >
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <MilitaryTechIcon style={{ color: 'white' }} />
                        </ListItemIcon>
                        <ListItemText style={{ color: 'white' }} primary="Matric ball" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <CelebrationIcon style={{ color: 'white' }} />
                        </ListItemIcon>
                        <ListItemText style={{ color: 'white' }} primary="Weddings" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <CameraAltIcon style={{ color: 'white' }} />
                        </ListItemIcon>
                        <ListItemText style={{ color: 'white' }} primary="Photo shoot / videos" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <CelebrationIcon style={{ color: 'white' }} />
                        </ListItemIcon>
                        <ListItemText style={{ color: 'white' }} primary="Any other occasion of your choice! " />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <ManIcon style={{ color: 'white' }} />
                        </ListItemIcon>
                        <ListItemText style={{ color: 'white' }} primary=" We also offer a chauffeur at an extra cost " />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </TreeItem>

                <TreeItem nodeId="2" label="Airport pick ups and drop off"  className={classes.treeItem}>
                  <List >
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemText style={{ color: 'white' }}
                         primary=" If you are travelling, you don't have to worry. We got you covered!
                         Book a car and we will deliver it to your location!"
                          />
                      </ListItemButton>
                    </ListItem>
                  </List>
                
                </TreeItem>

                <TreeItem nodeId="3" label="VIP Escort"  className={classes.treeItem}>
                  <List >
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <MilitaryTechIcon style={{ color: 'white' }} />
                        </ListItemIcon>
                        <ListItemText style={{ color: 'white' }} primary="Bullet Proof vehicles" />
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemIcon>
                          <ManIcon style={{ color: 'white' }} />
                        </ListItemIcon>
                        <ListItemText style={{ color: 'white' }} primary="Chauffeur services" />
                      </ListItemButton>
                    </ListItem>
                  </List>
                </TreeItem>

                <TreeItem nodeId="4" label=" Vehicles"  className={classes.treeItem}>
                <List >
                    <ListItem disablePadding>
                      <ListItemButton>
                        <ListItemText style={{ color: 'white' }}
                         primary=" We have a variety of cars and options that you can choose whats works the best for you!"
                          />
                      </ListItemButton>
                    </ListItem>
                  </List>
              
                </TreeItem>
              </TreeView>

            </Card>
          </Col>
        </Row>



      </div>

    </>
  );
}

export default Tabs;

