import React from 'react';
import classNames from 'classnames';
import classes from './booking-button-small.module.css';


export default function SmallBookingButton({label, selected,onClick}){

    const btnClasses = classNames(
        classes.SmallBookingButton,
        (selected)? classes.selected : null
    );
    return (

        <button className={btnClasses} onClick={onClick}>{label}</button>
    );
}

