
 import axios from 'axios';
// import https from 'https';
// import querystring from 'querystring';
// import fetch from 'node-fetch';
// import { Buffer } from 'buffer';
// global.Buffer = Buffer;

const paymentUrl = 'https://test.ppay.io/merchant/api/payments/generatePaymentLinkApi.json'; 

async function payment(amount){

    if(!amount || isNaN(amount)) return;

    //const finalAmount = parseFloat(amount)?.toFixed(2).toString();
    const request = async () => {

        const body = JSON.stringify(
            {
                "Authentication": {
                "userid": "8ac7a4c88017ef1d01801936fbc00835",
                "password": "4ZCfcywed5",
                "entityid": "8ac7a4c98017e58e0180193cf8d4055f"
                },
                "Payment": {
                "merchantInvoiceId": "RHG45",
                "amount": amount,
                "currency": "ZAR",
                "files": [],
                "sendEmail": true,
                "sendSms": true,
                "emailCc": "check@test.com,msaman1@hotmail.com",
                "emailBcc": "",
                "expiryTime":120,
                "notes":"checking demo"
                },
                "Customer": {
                "givenName": "abc",
                "surname": "efg",
                "email": "test12@gmail.com",
                "mobile": "123456789"
                }
                });


        const options = {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            // headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: body,
            url: paymentUrl
        };
        const res = await axios(options);

        return await res.json();
    };
    
    const response = await request().catch(()=> console.error("cant get payment url"));

    return response?.response?.url || null;
}

export default payment;





// async function payment(amount){

//     if(!amount || isNaN(amount)) return;

//     const finalAmount = parseFloat(amount)?.toFixed(2).toString();
//     const request = async () => {


//         const path='/v1/checkouts';
//         const data = querystring.stringify({
//             'entityId':'8ac7a4c98017e58e0180193cf8d4055f',
//             'amount': finalAmount, //'92.00'
//             'currency':'ZAR',
//             'paymentType':'DB'
//         });
//         const options = {
//             port: 443,
//             host: 'eu-test.oppwa.com',
//             path: path,
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/x-www-form-urlencoded',
//                 'Content-Length': data.length,
//                 // 'Authorization':'Bearer OGE4Mjk0MTc0ZTczNWQwYzAxNGU3OGNmMjY2YjE3OTR8cXl5ZkhDTjgzZQ=='
//                 'Authorization':'Bearer OGFjN2E0Yzg4MDE3ZWYxZDAxODAxOTM2ZmJjMDA4MzV8NFpDZmN5d2VkNQ=='
//             }
//             // user 8ac7a4c88017ef1d01801936fbc00835
//             // pass 4ZCfcywed5
//         };
//         return new Promise((resolve, reject) => {
//             const postRequest = https.request(options, function(res) {
//                 const buf = [];
//                 res.on('data', chunk => {
//                     buf.push(Buffer.from(chunk));
//                 });
//                 res.on('end', () => {
//                     const jsonString = Buffer.concat(buf).toString('utf8');
//                     try {
//                         resolve(JSON.parse(jsonString));
//                     } catch (error) {
//                         reject(error);
//                     }
//                 });
//             });
//             postRequest.on('error', reject);
//             postRequest.write(data);
//             postRequest.end();
//         });
//     };
    
//     const response = await request().catch(console.error);

//     return response?.id || null;
// }
