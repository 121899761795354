import React from "react";
import classes from '../Rentals.module.css';
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ScienceIcon from '@mui/icons-material/Science';
import SecurityIcon from '@mui/icons-material/Security';
import CelebrationIcon from '@mui/icons-material/Celebration';
import ElectricCarIcon from '@mui/icons-material/ElectricCar';
import DiscountIcon from '@mui/icons-material/Discount';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import LocalConvenienceStoreIcon from '@mui/icons-material/LocalConvenienceStore';
import Divider from '@mui/material/Divider';
// core components

function Mission() {
  const [iconPills, setIconPills] = React.useState("1");
  const [pills, setPills] = React.useState("1");
  return (
    <>
      <div className="section section-tabs"  >
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="10" xl="12" style={{ textAlign: 'center' }}>

              <Card className={classes.tabContainer} style={{ borderRadius: '8px', minHeight: '0' , margin: 'auto' }}>
                <CardHeader>
                  <p className="category"
                    style={{ textAlign: 'center', color:"#c1a456", marginTop: 25, fontSize: 25 }}>Our Mission</p>

                  <div style={{textAlign:'center'}}>
                    <Divider sx={{ bgcolor: "#c1a456", width:"80%",margin:'auto'}}/>
                    </div>
                    
                </CardHeader>
                <CardBody style={{color:'white', textAlign: 'center', paddingBottom: 50, fontSize: 19 }}>
                At Hurrican we aim to provide everyone with an opportunity to explore our beautiful city. With  our safe and comfortable vehicles, you can experience Cape Town in the most stylish and affordable way. 


                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

    </>
  );
}

export default Mission;
