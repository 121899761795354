import React from "react";
import HurricaneGlossyWindow from "./HurricaneGlossyWindow.js";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/Info';
import Link from '@mui/material/Link';

export default function GlossyMessage({ message, type }) {


    const lowCaseType = type?.toLowerCase();


    return (
        
        <HurricaneGlossyWindow message={message}>

            {
                lowCaseType && <div style={{fontSize: 200, marginTop: -67}}>
                {
                    (lowCaseType === 'success') && <CheckCircleOutlineIcon color='success' fontSize='inherit'/>
                }
                {
                    (lowCaseType === 'error') && <ErrorOutlineIcon color='error' fontSize='inherit'/>
                }
                {
                    (lowCaseType === 'info') && <InfoIcon color='info' fontSize='inherit'/>
                }
                </div>   
            }
            
            <div>
                <Link href="/" underline="hover" color="#3bb4dc">
                    Click here to go back to main page
                </Link>
            </div>
           
        </HurricaneGlossyWindow>
    )
}
