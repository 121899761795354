// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set, onValue, update, remove } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCu_gP1kJxsI7ybJnK9hDM6SXbfCkjER1I",
  authDomain: "hurricane-2bfc0.firebaseapp.com",
  projectId: "hurricane-2bfc0",
  storageBucket: "hurricane-2bfc0.appspot.com",
  messagingSenderId: "243744677004",
  appId: "1:243744677004:web:8a29675547b75963932fa9",
  databaseURL: "https://hurricane-2bfc0-default-rtdb.asia-southeast1.firebasedatabase.app"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
console.log(app);

export function dbSave(schema, jsonData) {
  const db = getDatabase();
  const reference = ref(db, schema);

  set(reference, jsonData)
  .then((data) => {
    // Data saved successfully!
    console.log(data);
  })
  .catch((error) => {
    // The write failed...
    console.error(error);
  });
}

export function dbUpdate(schema, jsonData) {
  const db = getDatabase();

  // Write the new post's data simultaneously in the posts list and the user's post list.
  const updates = {};
  updates[schema] = jsonData;

  return update(ref(db), updates);
}

export function dbListenTo(schema, onNewData) {
  const db = getDatabase();
  const reference = ref(db, schema);
  onValue(reference, (snapshot) => {
    const data = snapshot.val();
    onNewData?.(data);
    // updateStarCount(postElement, data);
  });
}

export function dbDelete(schema) {
  const db = getDatabase();
  const reference = ref(db, schema);

  remove(reference)
  .then((data) => {
    // Data removed successfully!
    console.log(data);
  })
  .catch((error) => {
    // The remove failed...
    console.error(error);
  });
}
