import React from "react";

// reactstrap components
// import {
// } from "reactstrap";

// core components
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import IndexHeader from "components/Headers/IndexHeader.js";
import DarkFooter from "components/Footers/DarkFooter.js";
import classes from './Rentals.module.css';
import { Container } from "reactstrap";
// sections for this page
import Images from "./index-sections/Images.js";
import BasicElements from "./index-sections/BasicElements.js";
import Navbars from "./index-sections/Navbars.js";
import TabsV from "./index-sections/Tabs.js";
import WhyUs from "./index-sections/WhyUs.js";
import Mission from "./index-sections/Mission.js";
import Pagination from "./index-sections/Pagination.js";
import Notifications from "./index-sections/Notifications.js";
import Typography from "./index-sections/Typography.js";
import Javascript from "./index-sections/Javascript.js";
import Carousel from "./index-sections/Carousel.js";
import NucleoIcons from "./index-sections/NucleoIcons.js";
import CompleteExamples from "./index-sections/CompleteExamples.js";
import SignUp from "./index-sections/SignUp.js";
import Examples from "./index-sections/Examples.js";
import Download from "./index-sections/Download.js";
import { ToastContainer, toast } from 'react-toastify';

function Index({setRentDetails}) {
  React.useEffect(() => {
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
  
    <div className={classes.bg}
     style={{backgroundImage:"url(" + require("assets/img/header.jpg").default + ")",}}
     >
     
     <div className={classes.bgFilter}>
     <IndexNavbar />
      <div className="wrapper">
        <IndexHeader setRentDetails={setRentDetails} />
        <div className="main">
          {/* <Images /> */}
          {/* <BasicElements />
          <Navbars /> */}

          <WhyUs />

          <TabsV />
          <Mission />
          {/* <Pagination />
          <Notifications />
          <Typography />
          <Javascript /> */}
                                   {/* <Carousel /> */}
          {/* <NucleoIcons />
          <CompleteExamples />
          <SignUp />
          <Examples />
          <Download /> */}
          <ToastContainer style={{top:130}} />
        </div>
        
        <DarkFooter />
      </div>
     </div>

      </div>
    
  );
}

export default Index;
