import * as React from 'react';
import AlarmIcon from '@mui/icons-material/Alarm';
import SnoozeIcon from '@mui/icons-material/Snooze';
import TextField from '@mui/material/TextField';
import ClockIcon from '@mui/icons-material/AccessTime';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import MobileDateTimePicker from '@mui/lab/MobileDateTimePicker';
import Stack from '@mui/material/Stack';
import BigBookingButton from './booking-button-big.js';
import EventIcon from '@mui/icons-material/Event';

export default function DatePickerButton({ label , defaultDate, setDate }) {
  const [clearedDate, setClearedDate] = React.useState(null);

  const [value, setValue] = React.useState(defaultDate || new Date());
  const [open, setOpen] = React.useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3} style={{display: 'inline-block'}}>
        <MobileDateTimePicker
          open={open}
          onClose={() => setOpen(false)}
          value={value}
          onChange={
            (newValue) => {
              setValue(newValue);
              setDate(newValue);
            }
          }
          label={label}
          onError={console.log}
          minDate={new Date()}
          inputFormat="yyyy/MM/dd hh:mm a"
          mask="___-__-__ __:__ _M"
          // renderInput={(params) => <TextField sx={{border: 'none'}} {...params} />
          renderInput={({ inputRef, inputProps, InputProps }) => (

              <BigBookingButton 
                label={label} 
                hint={<input ref={inputRef} {...inputProps} style={{color: '#fff', border: 'none', background: 'none', width: '120px'}}/>} 
                icon={<EventIcon fontSize="large" />} 
                onClick={e => setOpen(true)}
              />
            )}
        />
      </Stack>
    </LocalizationProvider>
  );
}